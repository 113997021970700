import {List} from 'immutable'
import {FormGridRowValue} from '../../../utils/DataTypeMapper'
import {uuid} from '../../../utils/utils'
import {IReceiptItem} from '../../types/ISmartDocsResult'
import {FieldAndColumnName} from '../../validator/FieldValidatorDef'
import {DateField, DetectedField, StringField} from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'

export class Cas360SignatureItem extends ModelObject implements IItemObject {
    constructor(
        readonly name?: StringField,
        readonly ifSigned?: StringField,
        readonly dateSigned?: DateField
    ) {
        super()
    }

    copy({
        name = this.name,
        ifSigned = this.ifSigned,
        dateSigned = this.dateSigned
    }): Cas360SignatureItem {
        return new Cas360SignatureItem(name, ifSigned, dateSigned)
    }
    addColumn(
        gridColumnName: FieldAndColumnName,
        newValue: FormGridRowValue,
        modifiedBy: string
    ): Cas360SignatureItem {
        switch(gridColumnName) {
            case FieldAndColumnName.Cas360SignatureItem_Name:
                return this.copy({ name: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

            case FieldAndColumnName.Cas360SignatureItem_IfSigned:
                return this.copy({ ifSigned: new StringField(uuid(), newValue as string, [], List(), modifiedBy) })

            case FieldAndColumnName.Cas360SignatureItem_DateSigned:
                return this.copy({ dateSigned: new DateField(uuid(), newValue as moment.Moment, [], List(), modifiedBy)})

            default:
                return this
        }
    }

    deleteColumn(gridColumn: FieldAndColumnName): Cas360SignatureItem {
        return this.copy({ [gridColumn]: null })

    }

    fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
        switch(column) {
            case FieldAndColumnName.Cas360SignatureItem_Name:
                return this.name

            case FieldAndColumnName.Cas360SignatureItem_IfSigned:
                return this.ifSigned

            case FieldAndColumnName.Cas360SignatureItem_DateSigned:
                return this.dateSigned

            default:
                return undefined
        }
    }

    listFields(): List<DetectedField> {
        return DetectedField.detectedFieldFromObject(this)
    }

    toJson(): any {
        return {
            name: this.name?.toModelKeyValue(),
            ifSigned: this.ifSigned,
            dateSigned: this.dateSigned?.toModelKeyValue()
        } as IReceiptItem
    }

}
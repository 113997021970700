import {List} from 'immutable'
import {OcrDocument} from '../../OcrDocument'
import {
    IBusinessModel,
    ICAS360DocumentType,
    ICAS360FormModel,
    ICAS360Signature,
    IModelType
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {StringField} from '../DetectedField'
import {Cas360Form} from './Cas360Form'
import {Cas360SignatureItem} from './Cas360SignatureItem'

export class Cas360FormMapper extends BusinessModelMapper {
    constructor(
        document: OcrDocument,
        private readonly jsonModel: ICAS360FormModel
    ) {
        super(document)
    }

    fromJson(): Cas360Form {
        const cas360FormModel = this.jsonModel
        const formName = this.mapDocumentType(cas360FormModel.DocumentType)
        const signature = cas360FormModel.Signature?.map((signatureItem: ICAS360Signature) => {
                return new Cas360SignatureItem(
                    this.mapTextBlock(signatureItem.Name),
                    this.mapCas360FormSignatureDetectionResultItem(signatureItem.IfSigned),
                    this.mapDateBlock(signatureItem.DateSigned)
                )
            }
        )

        return new Cas360Form(
            cas360FormModel.Version,
            formName,
            this.mapTextBlock(cas360FormModel.EntityName),
            this.mapDoubleBlock(cas360FormModel.TraceNumber),
            signature ? List(signature) : List()
        )
    }

    mapDocumentType(documentType: ICAS360DocumentType | undefined): StringField | undefined {
        return this.mapTextBlock(documentType?.FormName)
    }

    static toJson(model: Cas360Form): IBusinessModel {
        return {
            ModelType: IModelType.CAS360Form,
            Version: model.version,
            DocumentType: {DocumentType: model.formName?.toString(), FormName: model.formName?.toModelKeyValue()},
            EntityName: model.entityName?.toModelKeyValue(),
            TraceNumber: model.traceNumber?.toModelKeyValue(),
            Signature: model.signature?.toArray().map(x => {
                return {
                    Name: x.name?.toModelKeyValue(),
                    IfSigned: x.ifSigned?.toString(),
                    DateSigned: x.dateSigned?.toModelKeyValue()
                }
            })
        } as ICAS360FormModel
    }
}
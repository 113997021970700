export enum BlockType {
    PAGE = 'PAGE',
    WORD = 'WORD',
    LINE = 'LINE',
    TABLE = 'TABLE',
    CELL = 'CELL',
    PARSED_LINE = 'PARSED_LINE'
}

export enum BlockRelationshipType {
    CHILD = 'CHILD',
    VALUE = 'VALUE'
}

// This type is defined to be used in writting back to smartdocs web and ONLY basic types ES can recognise
export enum IDataType {
    Dollar = 'Dollar',
    Date = 'Date',
    String = 'String',
    Number = 'Number'
}

export enum ITransactionTableHeaderName {
    Effect = 'Effect',
    TransactionDate = 'TransactionDate',
    Description = 'Description',
    Debit = 'Debit',
    Credit = 'Credit',
    Balance = 'Balance'
}

export enum IModelType {
    Unrecognized = 'Unrecognized',
    BankStatement = 'BankStatement',
    UnrealisedCapitalGainReport = 'UnrealisedCapitalGainReport',
    ContractNote = 'ContractNote',
    FreeForm = 'FreeForm',
    TestNote = 'TestNote',
    Invoice = 'Invoice',
    Receipt = 'Receipt',
    BusinessCard = 'BusinessCard',
    IdCard = 'IdCard',
    WaterBill = 'WaterBill',
    DividendStatement = 'Dividend',
    AtoActivityStatement = 'AtoActivityStatement',
    AtoPaygInstalmentReport = 'AtoPaygInstalmentReport',
    AtoQuarterlyPaygInstalmentNotice = 'AtoQuarterlyPaygInstalmentNotice',
    RolloverBenefitStatement = 'RolloverBenefitStatement',
    CAS360Form = 'CAS360Form',
    ChartOfAccounts360 = 'ChartOfAccounts360',
    BusinessModelType = 'BusinessModelType',
    BankAuthority = 'BankAuthority',
    RentalSummary = 'RentalSummary',
    AnnualTaxStatement = 'AnnualTaxStatement',
    ASICFee = 'ASICFee',
    CouncilRate = 'CouncilRate',
    UtilityBill = 'UtilityBill',
    PropertySettlementStatement = 'PropertySettlementStatement'
}

export interface IBoundingBox {
    Width: number
    Height: number
    Left: number
    Top: number
}

export interface IPolygon {
    X: number
    Y: number
}

export interface IGeometry {
    BoundingBox: IBoundingBox
    Polygon: [IPolygon, IPolygon, IPolygon, IPolygon]
}

export interface IRelationship {
    Type: BlockRelationshipType
    Ids: string[]
}

export interface IBlock {
    Id: string
    Page: number
    BlockType: BlockType
    Geometry: IGeometry
    Confidence: number
}

export interface IWordBlock extends IBlock {
    BlockType: BlockType.WORD
    Text: string
    TextType?: string
}

export interface ILineBlock extends IBlock {
    BlockType: BlockType.LINE
    Text: string
    Relationships: IRelationship[]
}

export interface ICellBlock extends IBlock {
    BlockType: BlockType.CELL
    RowIndex: number
    ColumnIndex: number
    RowSpan: number
    ColumnSpan: number
    Relationships?: IRelationship[]
}

export interface IPageBlock extends IBlock {
    BlockType: BlockType.PAGE
    Relationships: IRelationship[]
}

export interface ITableBlock extends IBlock {
    BlockType: BlockType.TABLE
    Relationships: IRelationship[]
}

export interface IModelKeyValue {
    DataType: IDataType
    WordIds: string[]
    Id?: string  // Old Json files don't have this field
    ParsedValue: string | number,
    Probability?: number
}

export type IRentalSummaryArray = Array<{
    Name: string,
    GstStatus: string,
    GstAmount?: IModelKeyValue,
    MoneyIn?: IModelKeyValue,
    MoneyOut?: IModelKeyValue
}>

export type IItemSummaryArray = Array<{
    TransactionType: TransactionType,
    Description?: IModelKeyValue,
    GstAmount?: IModelKeyValue,
}>

export interface ITransactionTableDataRow {
    TransactionDate?: IModelKeyValue
    Description?: IModelKeyValue
    Credit?: IModelKeyValue
    Debit?: IModelKeyValue
    Balance?: IModelKeyValue
}

export interface IAtoActivityStatementTransactionTableDataRow {
    ProcessedDate?: IModelKeyValue
    EffectiveDate?: IModelKeyValue
    Description?: IModelKeyValue
    Credit?: IModelKeyValue
    Debit?: IModelKeyValue
    Balance?: IModelKeyValue
}

export interface ITransactionTableSpecialRow {
    Name: string,
    DataRow: ITransactionTableDataRow
}

export interface IPaygInstalmentYearlyTableDataRow {
    TFN?: IModelKeyValue
    ClientName?: IModelKeyValue
    Quarter1?: IModelKeyValue
    Quarter2?: IModelKeyValue
    Quarter3?: IModelKeyValue
    Quarter4?: IModelKeyValue
    TotalInstalment?: IModelKeyValue
}


export interface IPaygInstalmentYearlyTableDataSpecialRow {
    ClientName?: IModelKeyValue // the actual received value missing DataType key
    TFN?: IModelKeyValue // the actual received value missing DataType key
}

export interface IAtoTableSpecialRow {
    Name: string,
    DataRow: IPaygInstalmentYearlyTableDataSpecialRow
}

export interface ITransactionTable {
    AccountName?: IModelKeyValue
    ABN?: IModelKeyValue
    BsbAccountNumber?: {
        Bsb?: IModelKeyValue
        AccountNumber?: IModelKeyValue
    }
    StatementPeriod?: {
        Start?: IModelKeyValue
        End?: IModelKeyValue
    }
    AccountBalances?: {
        OpeningBalance?: IModelKeyValue
        TotalCredits?: IModelKeyValue
        TotalDebits?: IModelKeyValue
        ClosingBalance?: IModelKeyValue
    }
    Table: {
        Headers?: ITransactionTableDataRow
        SpecialRows?: ITransactionTableSpecialRow[]
        DataRows?: ITransactionTableDataRow[]
    }
}

export interface IAtoTransactionTable {
    TfnNumber?: IModelKeyValue
    AbnNumber?: IModelKeyValue
    StatementPeriod?: {
        Start?: IModelKeyValue
        End?: IModelKeyValue
    }
    Table: {
        Headers?: IAtoActivityStatementTransactionTableDataRow
        SpecialRows?: IAtoActivityStatementTransactionTableDataRow[]
        DataRows?: IAtoActivityStatementTransactionTableDataRow[]
    }
}

export interface IPayGInstalmentYearlyTable {
    Table: {
        Headers?: IPaygInstalmentYearlyTableDataRow
        SpecialRows?: IPaygInstalmentYearlyTableDataSpecialRow[] // not used for now, need to confirm data before use.
        DataRows?: IPaygInstalmentYearlyTableDataRow[]
    }
}

export interface IDocument {
    DocumentMetadata: {
        PageDimensions: {
            Page: number
            Height: number
            Width: number
        }[]
    }
    Blocks: (IPageBlock | ILineBlock | IWordBlock | ICellBlock | IBlock)[]
}

export interface IModel {
    ModelType: IModelType
    Version: string
    PageIndexes: number[]
}

export interface IBankStatementModel extends IModel {
    ModelType: IModelType.BankStatement
    BankName: string
    FinancialInstitution: IModelKeyValue
    RecipientAndAddress: {
        Recipient: IModelKeyValue
        Address: IModelKeyValue
    }
    TransactionTables: ITransactionTable[]
    Currency: string
}

export interface ICAS360FormModel extends IModel {
    ModelType: IModelType.CAS360Form
    DocumentType?: ICAS360DocumentType
    EntityName?: IModelKeyValue
    TraceNumber?: IModelKeyValue
    Signature?: ICAS360Signature[]
}

export interface ICAS360DocumentType {
    DocumentType: string
    FormName: IModelKeyValue
}

export interface ICAS360Signature {
    Name: IModelKeyValue
    IfSigned: boolean
    DateSigned: IModelKeyValue
}

export interface IContractNoteModel extends IModel {
    ModelType: IModelType.ContractNote
    BrokerName?: IModelKeyValue,
    TradeType?: IModelKeyValue,
    TotalAmount?: IModelKeyValue,
    TransactionDate?: IModelKeyValue,
    SettlementDate?: IModelKeyValue,
    Unit?: IModelKeyValue,
    UnitPrice?: IModelKeyValue,
    Consideration?: IModelKeyValue,
    Brokerage?: IModelKeyValue,
    Gst?: IModelKeyValue,
    ReferenceNumber?: IModelKeyValue,
    AsxCode?: IModelKeyValue,
    OtherFee?: IModelKeyValue,
    SecurityName?: IModelKeyValue,
    SecurityType?: IModelKeyValue,
    Market?: IModelKeyValue,
    AccruedInterest?: IModelKeyValue,
    MaturityDate?: IModelKeyValue
}

export interface IReceiptModel extends IModel {
    ModelType: IModelType.Receipt
    ReceiptType?: IModelKeyValue,
    MerchantName?: IModelKeyValue,
    MerchantPhoneNumber?: IModelKeyValue,
    MerchantAddress?: IModelKeyValue,
    TransactionDate?: IModelKeyValue,
    TransactionTime?: IModelKeyValue,
    Total?: IModelKeyValue,
    Subtotal?: IModelKeyValue,
    Tax?: IModelKeyValue,
    Tip?: IModelKeyValue,
    Items?: IReceiptItem[]
}

export interface IReceiptItem {
    Name?: IModelKeyValue,
    Quantity?: IModelKeyValue,
    Price?: IModelKeyValue,
    TotalPrice?: IModelKeyValue,
}

export interface IBusinessCardModel extends IModel {
    ModelType: IModelType.BusinessCard,
    ContactNames?: IContactNames[],
    JobTitles?: IModelKeyValue[],
    Departments?: IModelKeyValue[],
    Emails?: IModelKeyValue[],
    Websites?: IModelKeyValue[],
    MobilePhones?: IModelKeyValue[],
    OtherPhones?: IModelKeyValue[],
    WorkPhones?: IModelKeyValue[],
    Faxes?: IModelKeyValue[],
    Address?: IModelKeyValue[],
    CompanyNames?: IModelKeyValue[],
}

export interface IContactNames {
    FirstName?: IModelKeyValue,
    LastName?: IModelKeyValue,
}


export interface IInvoiceModel extends IModel {
    ModelType: IModelType.Invoice,
    CustomerName?: IModelKeyValue,
    CustomerId?: IModelKeyValue,
    PurchaseOrder?: IModelKeyValue,
    InvoiceId?: IModelKeyValue,
    InvoiceDate?: IModelKeyValue,
    DueDate?: IModelKeyValue,
    VendorName?: IModelKeyValue,
    VendorAddress?: IModelKeyValue,
    VendorAddressRecipient?: IModelKeyValue,
    CustomerAddress?: IModelKeyValue,
    CustomerAddressRecipient?: IModelKeyValue,
    BillingAddress?: IModelKeyValue,
    BillingAddressRecipient?: IModelKeyValue,
    ShippingAddress?: IModelKeyValue,
    ShippingAddressRecipient?: IModelKeyValue,
    SubTotal?: IModelKeyValue,
    TotalTax?: IModelKeyValue,
    InvoiceTotal?: IModelKeyValue,
    AmountDue?: IModelKeyValue,
    ServiceAddress?: IModelKeyValue,
    ServiceAddressRecipient?: IModelKeyValue,
    RemittanceAddress?: IModelKeyValue,
    RemittanceAddressRecipient?: IModelKeyValue,
    ServiceStartDate?: IModelKeyValue,
    ServiceEndDate?: IModelKeyValue,
    PreviousUnpaidBalance?: IModelKeyValue,
    Items?: IInvoiceItem[]
}

export interface IInvoiceItem {
    Amount?: IModelKeyValue,
    Description?: IModelKeyValue,
    Quantity?: IModelKeyValue,
    UnitPrice?: IModelKeyValue,
    ProductCode?: IModelKeyValue,
    Unit?: IModelKeyValue,
    Date?: IModelKeyValue,
    Tax?: IModelKeyValue,
}

export interface IIdCardModel extends IModel {
    DocumentType?: IModelKeyValue
    MachineReadableZone?: IModelKeyValue
    Sex?: IModelKeyValue
    Nationality?: IModelKeyValue
    Address?: IModelKeyValue
    CountryRegion?: IModelKeyValue
    DateOfBirth?: IModelKeyValue
    DateOfExpiration?: IModelKeyValue
    DocumentNumber?: IModelKeyValue
    FirstName?: IModelKeyValue
    LastName?: IModelKeyValue
    Region?: IModelKeyValue
}

export interface IWaterBillModel extends IModel {
    ModelType: IModelType.WaterBill,
    PropertyAddress?: IModelKeyValue,
    IssueDate?: IModelKeyValue,
    DueDate?: IModelKeyValue,
    TotalAmount?: IModelKeyValue,
    ClientAddress?: IModelKeyValue
}

export interface IUtilityBillModel extends IModel {
    ModelType: IModelType.UtilityBill,
    PropertyAddress?: IModelKeyValue,
    IssueDate?: IModelKeyValue,
    DueDate?: IModelKeyValue,
    TotalAmount?: IModelKeyValue,
    AccountNumber?: IModelKeyValue,
    InvoiceNumber?: IModelKeyValue,
    CustomerName?: IModelKeyValue,
    OverDueAmount?: IModelKeyValue,
    CustomerABN?: IModelKeyValue,
    GSTAmount?: IModelKeyValue,
    CustomerAddress?: IModelKeyValue,
    SupplierName?: IModelKeyValue,
    SupplierABN?: IModelKeyValue,
    BillingStartDate?: IModelKeyValue,
    BillingEndDate?: IModelKeyValue,
    ServicePhoneNumber?: IModelKeyValue[],
}

export interface IDividendStatementModel extends IModel {
    ModelType: IModelType.DividendStatement,
    ShareCode?: IModelKeyValue,
    DividendFranked?: IModelKeyValue,
    DividendUnfranked?: IModelKeyValue,
    FrankingCredits?: IModelKeyValue,
    AssessableForeignIncome?: IModelKeyValue,
    ForeignIncomeTaxOffset?: IModelKeyValue,
    FrankingCreditsFromNZ?: IModelKeyValue,
    WithholdingTax?: IModelKeyValue,
    ConvertedNetDividend?: IModelKeyValue,
    IndividualTrustLIC?: IModelKeyValue,
    SMSFLIC?: IModelKeyValue,
    PaymentDate?: IModelKeyValue,
    RecordDate?: IModelKeyValue,
    NumberOfShares?: IModelKeyValue,
    DividendPerShare?: IModelKeyValue,
    GrossDividend?: IModelKeyValue,
    NetDividend?: IModelKeyValue,
    DrpNumberOfShares?: IModelKeyValue,
    PreDRPBalanceBringForward?: IModelKeyValue,
    DrpBalanceAvailable?: IModelKeyValue,
    DrpSharePrice?: IModelKeyValue,
    PostDRPShareNumber?: IModelKeyValue,
    PostDRPCreditBalance?: IModelKeyValue,
    CompanyTaxRate?: IModelKeyValue,
    FrankingPercentage?: IModelKeyValue,
    DrpShareCost?: IModelKeyValue,
    ExchangeRate?: IModelKeyValue,
    ForeignCurrency?: IModelKeyValue,
    SupplementaryDividendNZ?: IModelKeyValue,
    FeeDeduction?: IModelKeyValue,
    CapitalReturn?:IModelKeyValue,
    CapitalComponentPerShare?:IModelKeyValue
}

export interface IAtoActivityStatementModel extends IModel {
    ModelType: IModelType.AtoActivityStatement,
    Name: string,
    TransactionTables: IAtoTransactionTable[]
}

export interface IAtoPaygInstalmentReportModel extends IModel {
    ModelType: IModelType.AtoPaygInstalmentReport,
    Name: string,
    FinancialYear?: IModelKeyValue,
    LastUpdatedDate?: IModelKeyValue,
    TaxAgent?: IModelKeyValue,
    Table: IPayGInstalmentYearlyTable
}

export interface IRolloverBenefitStatementModel extends IModel {
    ModelType: IModelType.RolloverBenefitStatement,
    Name: string,
    ReceivingFundABN?: IModelKeyValue,
    ReceivingFundName?: IModelKeyValue,
    TransferringFundName?: IModelKeyValue,
    TransferringFundABN?: IModelKeyValue,
    Tfn?: IModelKeyValue,
    FullName?: IModelKeyValue,
    ServicePeriodStartDate?: IModelKeyValue,
    TaxFreeComponent?: IModelKeyValue,
    PreservedAmount?: IModelKeyValue,
    ElementTaxedInTheFund?: IModelKeyValue,
    ElementUntaxedInTheFund?: IModelKeyValue,
    RestrictedNonPreservedAmount?: IModelKeyValue,
    UnrestrictedNonPreservedAmount?: IModelKeyValue,
}

export interface IAnnualTaxStatementModel extends IModel {
    ModelType: IModelType.AnnualTaxStatement,
    Year?: IModelKeyValue,
    InvestName?: IModelKeyValue,
    InvestCode?: IModelKeyValue,
    OtherIncome?: IModelKeyValue[],
    Interest?: IModelKeyValue[],
    GrossFranked?: IModelKeyValue[],
    FrankingCredits?: IModelKeyValue[],
    Unfranked?: IModelKeyValue[],
    NetFranked?: IModelKeyValue[],
    DiscountCG?: IModelKeyValue[],
    OtherCG?: IModelKeyValue[],
    CgtConcession?: IModelKeyValue[],
    DiscountForCGCredits?: IModelKeyValue[],
    OtherForCGCredits?: IModelKeyValue[],
    DiscountForCG?: IModelKeyValue[],
    OtherForCG?: IModelKeyValue[],
    IndexCG?: IModelKeyValue[],
    GrossOtherForeignIncome?: IModelKeyValue[],
    ForeignCredits?: IModelKeyValue[],
    GrossForeignInc?: IModelKeyValue[],
    NZfrankingCredits?: IModelKeyValue[],
    TaxDeferred?: IModelKeyValue[],
    TaxExempt?: IModelKeyValue[],
    TaxFree?: IModelKeyValue[],
    AmitExcess?: IModelKeyValue[],
    AmitShortfall?: IModelKeyValue[],
    TfnCredits?: IModelKeyValue[],
    TrustDeduction?: IModelKeyValue[],
    OtherExpenses?: IModelKeyValue[],
    NetCash?: IModelKeyValue[],
    NetForeignIncome?: IModelKeyValue[],
}

export interface IASICFeeModel extends IModel {
    ModelType: IModelType.ASICFee,
    TotalDue?: IModelKeyValue,
    CompanyName?: IModelKeyValue,
    IssueDate?: IModelKeyValue,
    DueDate?: IModelKeyValue,
    ACN?: IModelKeyValue,
    CompanyRegisteredAddress?: IModelKeyValue,
    CompanyOfficeholder?: IModelKeyValue[],
    CompanyOfficeholderDOB?: IModelKeyValue[],
    CompanyMember?: IModelKeyValue[],
}

export interface ICouncilRateModel extends IModel{
    ModelType:IModelType.CouncilRate,
    PropertyAddress?: IModelKeyValue,
    IssueDate?: IModelKeyValue,
    CurrentAmountDueDate?: IModelKeyValue,
    CurrentAmountDue?:IModelKeyValue,
    FirstInstalmentDueDate?: IModelKeyValue,
    FirstInstalmentAmount?: IModelKeyValue,
    SecondInstalmentDueDate?: IModelKeyValue,
    SecondInstalmentAmount?: IModelKeyValue,
    ThirdInstalmentDueDate?: IModelKeyValue,
    ThirdInstalmentAmount?: IModelKeyValue,
    FourthInstalmentDueDate?: IModelKeyValue,
    FourthInstalmentAmount?: IModelKeyValue,
    DiscountTotalAmountDue?: IModelKeyValue,
    TotalAmount?: IModelKeyValue,
}

export interface IRentalSummaryModel extends IModel {
    ModelType: IModelType.RentalSummary,
    Name: string,
    StartDate?: IModelKeyValue,
    EndDate?: IModelKeyValue,
    Address?: IModelKeyValue,
    IncomeSummary?: IRentalSummaryArray,
    PaymentToOwner?: IRentalSummaryArray,
    ExpenseAgentsManagementFees?: IRentalSummaryArray,
    ExpenseAgentsCommissions?: IRentalSummaryArray,
    ExpenseElectricityAndGas?: IRentalSummaryArray,
    ExpenseWaterRates?: IRentalSummaryArray,
    ExpenseInsurancePremium?: IRentalSummaryArray,
    ExpenseGardenAndLawn?: IRentalSummaryArray,
    ExpenseStrataLevyFees?: IRentalSummaryArray,
    ExpenseAdvertising?: IRentalSummaryArray,
    ExpenseStationeryPhoneAndPostage?: IRentalSummaryArray,
    ExpensePestControl?: IRentalSummaryArray,
    ExpenseCleaning?: IRentalSummaryArray,
    ExpenseCouncilRate?: IRentalSummaryArray,
    ExpenseLandTax?: IRentalSummaryArray,
    ExpenseLegalFees?: IRentalSummaryArray,
    ExpenseSundry?: IRentalSummaryArray,
    ExpenseNonSpecified?: IRentalSummaryArray,
    ExpenseRepairsMaintenance?: IRentalSummaryArray,
    ExpensePropertyCapitalImprovement?: IRentalSummaryArray,
    ExpenseGST?: IRentalSummaryArray,
    PaymentStartDate?: IModelKeyValue,
    PaymentEndDate?: IModelKeyValue,
}

export interface IPropertySettlementStatement extends IModel {
    ModelType: IModelType.PropertySettlementStatement,
    PurchaserName?: IModelKeyValue,
    VendorName?: IModelKeyValue,
    SettlementDate?: IModelKeyValue,
    PropertyAddress?: IModelKeyValue,
    SaleOrPurchasePrice?: IItemSummaryArray,
    ExpenseCouncilRates?: IItemSummaryArray,
    ExpenseWaterRates?: IItemSummaryArray,
    ExpenseElectricityAndGas?: IItemSummaryArray,
    RentalIncome?: IItemSummaryArray,
    ExpenseAgentsCommissions?: IItemSummaryArray,
    ExpenseSolicitorFees?: IItemSummaryArray,
    ExpenseStationeryPhoneAndPostage?: IItemSummaryArray,
    ExpenseTitleFees?: IItemSummaryArray,
    SaleRelatedAgentFee?: IItemSummaryArray,
    DepositPaid?: IItemSummaryArray,
    ExpenseLandTax?: IItemSummaryArray,
    SearchFees?: IItemSummaryArray,
    SettlementFees?: IItemSummaryArray,
    GSTExpense?: IItemSummaryArray,
    TransferDuty?: IItemSummaryArray,
    DeductibleExpense?: IItemSummaryArray,
    RegistrationFees?: IItemSummaryArray,
    FundsByLoan?: IItemSummaryArray,
    FundsByPerson?: IItemSummaryArray,
    CertificateFees?: IItemSummaryArray,
    ExpenseStrataLevyFees?: IItemSummaryArray,
    PenaltyInterest?: IItemSummaryArray,
    MortgageReleaseFees?: IItemSummaryArray,
    StampDuty?: IItemSummaryArray,
    ExpensePestControl?: IItemSummaryArray,
    VendorFees?: IItemSummaryArray,
    ResidualMoney?: IItemSummaryArray,
}

export interface IAtoQuarterlyPaygInstalmentNoticeRecipientAndAddress {
    Recipient?: IModelKeyValue,
    Address?: IModelKeyValue
}

export interface IAtoQuarterlyPaygInstalmentNoticeModel extends IModel {
    ModelType: IModelType.AtoQuarterlyPaygInstalmentNotice,
    Name: string,
    DocumentId?: IModelKeyValue,
    Abn?: IModelKeyValue,
    RecipientAndAddress: IAtoQuarterlyPaygInstalmentNoticeRecipientAndAddress,
    Quarter?: IModelKeyValue, // ????? received differently format from the API
    Year?: IModelKeyValue,
    FormDueOn?: IModelKeyValue,
    PaymentDueOn?: IModelKeyValue,
    InstalmentAmount?: IModelKeyValue,
    AtoCode?: IModelKeyValue,
    EftCode?: IModelKeyValue,
}

export interface IChartOfAccounts360Page {
    Code: string
    Id: string
    Name: string
    Probability: number
}

export interface IBusinessModelTypePage {
    Id: string
    ModelName: string
    Probability: number
}

export interface IChartOfAccounts360Model extends IModel {
    ModelType: IModelType.ChartOfAccounts360,
    Code: string,
    Name: string,
    Probability: number,
    Pages: IChartOfAccounts360Page[]
}

export interface IBankAuthorityModel extends IModel {
    ModelType: IModelType.BankAuthority,
    Name: string,
    PracticeName?: IModelKeyValue,
    AcnAbn?: IModelKeyValue,
    Accounts: IBankAuthorityAccount[],
    SignatoriesName?: IModelKeyValue[],
    Signature?: IModelKeyValue[],
    SignatureDate?: IModelKeyValue[],
    SmsfName?: IModelKeyValue,
}

export interface IBankAuthorityAccount {
    AccountName?: IModelKeyValue,
    AccountNumber?: IModelKeyValue,
    Bsb?: IModelKeyValue,
}

export interface IBusinessModelTypeModel extends IModel {
    ModelType: IModelType.BusinessModelType,
    ModelName: string,
    Probability: number,
    Pages: IBusinessModelTypePage[]
}

export interface IWordAnnotation {
    WordId: string // "e1020154-50cd-4aa5-8761-9ea4ec7e298d",
    Annotation: string // "Trade",
    Probability: number // 0.99,
    Source: string // "ai.contractnote"
}

export interface IWordText {
    WordId: string,
    Text: string,
    Source: string
}

export interface IPageAnnotation {
    Annotation: string,
    Probability: number,
    Source: string
}

export interface IPageAnnotations {
    Page: string,
    Annotations: IPageAnnotation[]
}

export interface IObjectAnnotation {
    Annotation: string,
    Confidence: number,
    XMin: number,
    YMin: number,
    XMax: number,
    YMax: number,
    Source: string
}

export interface IObjectAnnotations {
    Page: string,
    Annotations: IObjectAnnotation[]
}

export type IBusinessModel = IBankStatementModel
    | IBusinessModelTypeModel
    | ICAS360FormModel
    | IContractNoteModel
    | IReceiptModel
    | IInvoiceModel
    | IIdCardModel
    | IBusinessCardModel
    | IWaterBillModel
    | IDividendStatementModel
    | IAtoActivityStatementModel
    | IAtoPaygInstalmentReportModel
    | IRolloverBenefitStatementModel
    | IAtoQuarterlyPaygInstalmentNoticeModel
    | IBankAuthorityModel
    | IAnnualTaxStatementModel
    | IASICFeeModel
    | IModel
    | IPropertySettlementStatement

export interface ISmartDocsResult {
    FileId?: string,
    Document?: IDocument,
    WordAnnotations?: IWordAnnotation[],
    WordTexts?: IWordText[],
    PageAnnotations?: IPageAnnotations[],
    BusinessModels?: IBusinessModel[],
    ObjectAnnotations?: IObjectAnnotations[],
}


export enum TransactionType {
    Credit, Debit
}
import {List} from 'immutable'
import * as _ from 'lodash'
import moment from 'moment'
import {compact, uuid} from '../../utils/utils'
import {ElementLocator} from '../locator/ElementLocator'
import {OcrDocument} from '../OcrDocument'
import { IItemSummaryArray, IModelKeyValue, IRentalSummaryArray } from '../types/ISmartDocsResult'
import BusinessModel from './BusinessModel'
import {DateField, DollarField, DoubleField, ItemSummaryField, RentalField, StringField} from './DetectedField'

export abstract class BusinessModelMapper {
  protected readonly locator: ElementLocator
  protected constructor(
    protected readonly document: OcrDocument
  ) {
    this.locator = document.elementLocator
  }

  abstract fromJson(): BusinessModel

  private newIdIfNull(id?: string): string {
    if (id) {
      return id
    } else {
      return uuid()
    }
  }
  protected mapCas360FormSignatureDetectionResultItem(item?: boolean): StringField | undefined {
    if (_.isNil(item)) return undefined
    return new StringField(
      uuid(),
      item.toString(),
      [],
      List()
    )
  }

  protected mapTextBlock(keyValueDTO?: IModelKeyValue): StringField | undefined {
    if (_.isNil(keyValueDTO)) return undefined
    return new StringField(
      this.newIdIfNull(keyValueDTO.Id),
      keyValueDTO.ParsedValue as string,
      keyValueDTO.WordIds,
      this.locator.wordsFromArray(keyValueDTO.WordIds)
    )
  }

  protected mapTextBlocks(keyValueDTOs?: IModelKeyValue[]): List<StringField> {
    if (keyValueDTOs) {
      const list = keyValueDTOs.map(dto => this.mapTextBlock(dto))
      return compact(List(list))
    } else {
      return List()
    }
  }

  protected mapDateBlock(keyValueDTO?: IModelKeyValue): DateField | undefined {
    if (_.isNil(keyValueDTO)) return undefined
    return new DateField(
      this.newIdIfNull(keyValueDTO.Id),
      moment(keyValueDTO.ParsedValue as string),
      keyValueDTO.WordIds,
      this.locator.wordsFromArray(keyValueDTO.WordIds)
    )
  }

  protected mapDateBlocks(keyValueDTOs?: IModelKeyValue[]): List<DateField> {
    if (keyValueDTOs) {
      const list = keyValueDTOs.map(dto => this.mapDateBlock(dto))
      return compact(List(list))
    } else {
      return List()
    }
  }
  protected mapDollarBlock(keyValueDTO?: IModelKeyValue): DollarField | undefined {
    if (_.isNil(keyValueDTO)) return undefined
    return new DollarField(
      this.newIdIfNull(keyValueDTO.Id),
      keyValueDTO.ParsedValue as number,
      keyValueDTO.WordIds,
      this.locator.wordsFromArray(keyValueDTO.WordIds)
    )
  }

  protected mapDoubleBlock(keyValueDTO?: IModelKeyValue): DoubleField | undefined {
    if (_.isNil(keyValueDTO)) return undefined
    return new DoubleField(
      this.newIdIfNull(keyValueDTO.Id),
      keyValueDTO.ParsedValue as number,
      keyValueDTO.WordIds,
      this.locator.wordsFromArray(keyValueDTO.WordIds)
    )
  }

  protected mapDoubleBlocks(keyValueDTO?: IModelKeyValue[]): DoubleField[]  {
    if (_.isNil(keyValueDTO)) return []
    return keyValueDTO.map(data =>{
        return new DoubleField(
          this.newIdIfNull(data.Id),
          data.ParsedValue as number,
          data.WordIds,
          this.locator.wordsFromArray(data.WordIds))
    })
  }

  protected mapRentalBlocks(rentalSummaryArray?: IRentalSummaryArray): RentalField[] | undefined {
    if (_.isNil(rentalSummaryArray)) return undefined

    return rentalSummaryArray.map(d => (
      new RentalField(
        this.mapDollarBlock(d.GstAmount),
        this.mapDollarBlock(d.MoneyIn),
        this.mapDollarBlock(d.MoneyOut)
      )
    ))
  }

  protected mapPSSBlocks(itemSummaryArray?: IItemSummaryArray): ItemSummaryField[] | undefined {
    if (_.isNil(itemSummaryArray)) return undefined

    return itemSummaryArray.map(d =>
      new ItemSummaryField(
        d.TransactionType,
        this.mapTextBlock(d.Description),
        this.mapDollarBlock(d.GstAmount),
      )
    )
  }
}

import {List} from 'immutable'
import * as _ from 'lodash'
import {OcrDocument} from '../OcrDocument'
import {
    IAnnualTaxStatementModel,
    IASICFeeModel,
    IAtoActivityStatementModel,
    IAtoPaygInstalmentReportModel,
    IAtoQuarterlyPaygInstalmentNoticeModel,
    IBankAuthorityModel,
    IBankStatementModel,
    IBusinessCardModel,
    IBusinessModel,
    IBusinessModelTypeModel,
    ICAS360FormModel,
    IChartOfAccounts360Model,
    IContractNoteModel,
    ICouncilRateModel,
    IDividendStatementModel,
    IIdCardModel,
    IInvoiceModel,
    IModel,
    IModelType,
    IPropertySettlementStatement,
    IReceiptModel,
    IRentalSummaryModel,
    IRolloverBenefitStatementModel,
    IUtilityBillModel,
    IWaterBillModel
} from '../types/ISmartDocsResult'
import {AnnualTaxStatementMapper} from './annualTaxStatement/AnnualTaxStatementMapper'
import {ASICFeeMapper} from './asicFee/ASICFeeMapper'
import {AtoActivityStatementMapper} from './atoActivityStatement/AtoActivityStatementMapper'
import {AtoPaygInstalmentReportMapper} from './atoPaygInstalmentReport/AtoPaygInstalmentReportMapper'
import {
    AtoQuarterlyPaygInstalmentNoticeMapper
} from './atoQuarterlyPaygInstalmentNotice/AtoQuarterlyPaygInstalmentNoticeMapper'
import {BankAuthorityMapper} from './bankAuthority/BankAuthorityMapper'
import {BankStatementMapper} from './bankstatement/BankStatementMapper'
import {BusinessCardMapper} from './businesscard/BusinessCardMapper'
import BusinessModel from './BusinessModel'
import {BusinessModelTypeMapper} from './businessModelType/BusinessModelTypeMapper'
import {Cas360FormMapper} from "./cas360Form/Cas360FormMapper";
import {ChartOfAccounts360Mapper} from './ChartOfAccounts360/ChartOfAccounts360Mapper'
import {ContractNoteMapper} from './contractnote/ContractNoteMapper'
import {CouncilRateMapper} from './councilRate/CouncilRateMapper'
import {DividendStatementMapper} from './dividendstatement/DividendStatementMapper'
import {IdCardMapper} from './idcard/IdCardMapper'
import {InvoiceMapper} from './invoice/InvoiceMapper'
import {PropertySettlementStatementMapper} from './propertySettlementStatement/PropertySettlementStatementMapper'
import {ReceiptMapper} from './receipt/ReceiptMapper'
import {RentalSummaryMapper} from './rentalSummary/RentalSummaryMapper'
import {RolloverBenefitStatementMapper} from './rolloverBenefitStatement/RolloverBenefitStatementMapper'
import {UtilityBillMapper} from './utilityBill/UtilityBillMapper'
import {WaterBillMapper} from './waterbill/WaterBillMapper'

export class BusinessModels {
    constructor(
        readonly businessModels: List<BusinessModel>
    ) {
    }

    static fromJson(document: OcrDocument, modelDTOs?: IModel[]): BusinessModels {
        if (modelDTOs) {
            const parsedModels = _.compact(
                modelDTOs.map((model) => {
                    switch (model.ModelType) {
                        case IModelType.BankStatement:
                            return new BankStatementMapper(document, model as IBankStatementModel).fromJson()
                        case IModelType.CAS360Form:
                            return new Cas360FormMapper(document, model as ICAS360FormModel).fromJson()
                        case IModelType.ContractNote:
                            return new ContractNoteMapper(document, model as IContractNoteModel).fromJson()
                        case IModelType.Receipt:
                            return new ReceiptMapper(document, model as IReceiptModel).fromJson()
                        case IModelType.Invoice:
                            return new InvoiceMapper(document, model as IInvoiceModel).fromJson()
                        case IModelType.IdCard:
                            return new IdCardMapper(document, model as IIdCardModel).fromJson()
                        case IModelType.BusinessCard:
                            return new BusinessCardMapper(document, model as IBusinessCardModel).fromJson()
                        case IModelType.WaterBill:
                            return new WaterBillMapper(document, model as IWaterBillModel).fromJson()
                        case IModelType.UtilityBill:
                            return new UtilityBillMapper(document, model as IUtilityBillModel).fromJson()
                        case IModelType.DividendStatement:
                            return new DividendStatementMapper(document, model as IDividendStatementModel).fromJson()
                        case IModelType.AtoActivityStatement:
                            return new AtoActivityStatementMapper(document, model as IAtoActivityStatementModel).fromJson()
                        case IModelType.AtoPaygInstalmentReport:
                            return new AtoPaygInstalmentReportMapper(document, model as IAtoPaygInstalmentReportModel).fromJson()
                        case IModelType.AtoQuarterlyPaygInstalmentNotice:
                            return new AtoQuarterlyPaygInstalmentNoticeMapper(document, model as IAtoQuarterlyPaygInstalmentNoticeModel).fromJson()
                        case IModelType.ChartOfAccounts360:
                            return new ChartOfAccounts360Mapper(document, model as IChartOfAccounts360Model).fromJson()
                        case IModelType.BusinessModelType:
                            return new BusinessModelTypeMapper(document, model as IBusinessModelTypeModel).fromJson()
                        case IModelType.BankAuthority:
                            return new BankAuthorityMapper(document, model as IBankAuthorityModel).fromJson()
                        case IModelType.RolloverBenefitStatement:
                            return new RolloverBenefitStatementMapper(document, model as IRolloverBenefitStatementModel).fromJson()
                        case IModelType.RentalSummary:
                            return new RentalSummaryMapper(document, model as IRentalSummaryModel).fromJson()
                        case IModelType.AnnualTaxStatement:
                            return new AnnualTaxStatementMapper(document, model as IAnnualTaxStatementModel).fromJson()
                        case IModelType.ASICFee:
                            return new ASICFeeMapper(document, model as IASICFeeModel).fromJson()
                        case IModelType.CouncilRate:
                            return new CouncilRateMapper(document, model as ICouncilRateModel).fromJson()
                        case IModelType.PropertySettlementStatement:
                            return new PropertySettlementStatementMapper(document, model as IPropertySettlementStatement).fromJson()
                        default:
                            return null
                    }
                })
            )
            return new BusinessModels(List(parsedModels))
        } else {
            return new BusinessModels(List())
        }
    }

    toJson(): IBusinessModel[] {
        return this.businessModels.toArray().map(model => model.toJson())
    }

    first(): BusinessModel | undefined {
        if (this.size() > 0) {
            return this.businessModels.get(0)
        } else {
            return undefined
        }
    }

    size(): number {
        return this.businessModels.size
    }

    pageCount(): number {
        return _.sum(this.mapToArray(model => model.pageIndexes.length))
    }

    getModelByPage(pageIndex: number): BusinessModel | undefined {
        return this.find(model => model.pageIndexes().indexOf(pageIndex) > -1)
    }

    mapToArray<Z>(mapper: (value: BusinessModel, index?: number) => Z): Z[] {
        return this.businessModels.map(mapper).valueSeq().toArray()
    }

    reduce<T>(f: (reduction: T, value: BusinessModel, key: number) => T, init: T): T {
        return this.businessModels.reduce(f, init)
    }

    find(predicate: (value: BusinessModel, key: number) => boolean): BusinessModel | undefined {
        return this.businessModels.find(predicate)
    }
}

import {List} from 'immutable'
import {IModelType} from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DoubleField, StringField} from '../DetectedField'
import {Cas360SignatureItem} from './Cas360SignatureItem'

export class Cas360Form extends BusinessModel {
    constructor(
        version: string,
        readonly formName?: StringField,
        readonly entityName?: StringField,
        readonly traceNumber?: DoubleField,
        readonly signature?: List<Cas360SignatureItem>
    ) {
        super(IModelType.CAS360Form, version)
    }

    copy({
             version = this.version,
             formName = this.formName,
             entityName = this.entityName,
             traceNumber = this.traceNumber,
                signature = this.signature
         }): Cas360Form {
        return new Cas360Form(
            version,
            formName,
            entityName,
            traceNumber,
            signature
        )
    }
}